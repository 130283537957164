import { Alert, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';


export const MenuitemSettings = () => {
  const { api, error, isLoading } = useApi();

  useEffect(() => {
    
  }, []);

  const setDefault = () => {
  }

  const reset = () => {
    
  }

  const saveSettings = () => {
   
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <Typography variant='h5' sx={{ mt: 2 }}>Menu settings</Typography>
      <InfoArea title='Logo'>
      <Typography variant='body1' sx={{ mt: 2 }}>Items</Typography>

      </InfoArea>
      <InfoArea title='Colors'>
      <Typography variant='body1' sx={{ mt: 2 }}>Access</Typography>
        
      </InfoArea>

      {error && <Alert severity="error">{error}</Alert>}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='info' onClick={setDefault}>Default</Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Button variant='contained' color='darkgrey' onClick={reset}>Reset</Button>
          <Button
            variant='contained'
            color='info'
            onClick={saveSettings}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={22} />}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}