import { AppBar, Box, Paper, Tab, Tabs, tabsClasses, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { TabPanel, TabProps } from '../../../components/ui/TabPanel';
import { MenuitemSettings } from './MenuitemSettings';
import { ModulSettings } from './ModulSettings';
import { PageSettings } from './PageSettings';
import { RoleSettings } from './RoleSettings';

export const AdminSettings = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, width: '100%', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static" sx={
          {
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black",
          }
        }>
          <Tabs
            value={tabIndex}
            onChange={(_, index) => setTabIndex(index)}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            aria-label="admin settings tabs"
            scrollButtons
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Page" sx={{ fontWeight: "bold" }} {...TabProps(0)} />
            <Tab label="Menu" sx={{ fontWeight: "bold" }} {...TabProps(1)} />
            <Tab label="Role" sx={{ fontWeight: "bold" }} {...TabProps(2)} />
            <Tab label="Module" sx={{ fontWeight: "bold" }} {...TabProps(3)} />
            <Tab label="Item 4" sx={{ fontWeight: "bold" }} {...TabProps(4)} />
            <Tab label="Item 5" sx={{ fontWeight: "bold" }} {...TabProps(5)} />
            <Tab label="Item 6" sx={{ fontWeight: "bold" }} {...TabProps(6)} />
            <Tab label="Item 7" sx={{ fontWeight: "bold" }} {...TabProps(7)} />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <PageSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <MenuitemSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RoleSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ModulSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        Item 4
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        Item 5
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        Item 6
      </TabPanel>
      <TabPanel value={tabIndex} index={7}>
        Item 7
      </TabPanel>
    </Paper>
  )
}